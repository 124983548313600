.choice {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-right: 4px;
  border-radius: 25px;
  border-width: 2px;
  cursor: pointer;
  flex: 1;
}
.choice:last-child {
  margin-right: 0;
}

/* .choiceActive {
  @apply border-indigo-600;
} */

.modal {
  max-width: 725px;
}

@media (min-width: 640px) {
  .choice {
    width: 33%;
  }
}
