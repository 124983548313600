.gameLayout {
  width: 100%;
  min-height: calc(100vh);
  background: #101310;
  /* background: linear-gradient(135deg, #15121d 0%, #1b1233 100%); */
}

.gameLayoutInner {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  /* max-width: 1400px;
  margin: 0 auto; */
}

.leftSide {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 650px;
  min-height: 100vh;
  @apply p-8;
  @apply pb-16;
  @apply text-white;

  padding-bottom: 400px;
}

.rightSide {
  position: fixed;
  width: calc(100% - 650px);
  left: 650px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.editorWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 620px;
  padding: 30px 0 30px 30px;
  background: #121311;
  border-top: 1px solid #4b5663;
  z-index: 999;
}

.fieldContainer {
  /* width: 100%; */
  /* aspect-ratio: 1 / 1; */
  width: 45vw;
  height: 45vw;
  min-width: 300px;
  min-height: 300px;
  max-width: 650px;
  max-height: 650px;
}

.fieldContainerBoys {
  width: 45vw;
  min-width: 300px;
  max-width: 650px;
}

.fieldBackground {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: contain;
}

.regularText,
.regularTextNormalized {
  line-height: 1.7;
  font-size: 18px;
  letter-spacing: 0.5px;
}

.regularText {
  @apply mb-6;
}

.resizable {
  @apply relative;
  @apply resize-x;
  @apply overflow-auto;
}

.resizableAbsolute {
  @apply absolute;
  @apply resize-x;
  @apply overflow-auto;
}

.resizable::after,
.resizableAbsolute::after {
  position: absolute;
  z-index: 100;
  right: 0px;
  bottom: 0px;
  content: '';
  background: rgb(255 255 255 / 70%);
  width: 20px;
  height: 20px;
  border-radius: 40px 0px 0px;
}

@media (max-width: 768px) {
  .fieldContainer {
    width: 95vw;
    height: 95vw;
  }
}

@media (max-width: 1279px) {
  .leftSide {
    max-width: 550px;
  }

  .rightSide {
    width: calc(100% - 550px);
    left: 550px;
  }

  .editorWrapper {
    width: 520px;
  }
}

@media (max-width: 1024px) {
  .gameLayoutInner {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .leftSide {
    width: 100%;
    min-height: initial;
    @apply p-4;
  }

  .rightSide {
    width: 100%;
    position: initial;
    left: 0;
    flex-direction: column-reverse;
    min-height: initial;
    @apply mb-8;
  }

  .editorWrapper {
    position: initial;
    width: 100%;
    padding: 30px 0;
    background: transparent;
    border-top: none;
  }

  .regularText,
  .regularTextNormalized {
    font-size: 16px;
  }

  .fieldContainer {
    width: 75vw;
    height: 75vw;
  }

  .fieldContainerBoys {
    width: 75vw;
  }

  /* .fieldContainer {
    width: 96vw;
    height: 96vw;
    max-width: 96vw;
    max-height: 96vw;
  } */
}

/* other */

.fieldBackground {
  background-image: url('/gamesAssets/gridattack/gray-land.svg');
}
