.land {
  background-image: url('/gamesAssets/gridattack/green-land.svg');
  /* animation: land 2s ease-out infinite; */
  opacity: 0.8;
  background-size: cover;
  box-shadow: inset 0 -10px 0 0 #345222, inset -10px 0 0 0 #345222;
}
/*
@keyframes land {
  0% {
    opacity: 0.4;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 0.4;
  }
} */
