.land {
  position: relative;
}

.rightAnswer > div {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 10px dashed;
}

.rightAnswer.red > div {
  border-color: #a74343;
}

.rightAnswer.blue > div {
  border-color: #4355a7;
}

.rightAnswer.green > div {
  border-color: #6ca743;
}

.userAnswer.green {
  background-image: url('/gamesAssets/gridattack/green-land.svg');
  opacity: 0.8;
  background-size: cover;
  box-shadow: inset 0 -10px 0 0 #345222, inset -10px 0 0 0 #345222;
}

.userAnswer.red {
  background-image: url('/gamesAssets/gridattack/red-land.svg');
  opacity: 0.8;
  background-size: cover;
  box-shadow: inset 0 -10px 0 0 #652727, inset -10px 0 0 0 #652727;
}

.userAnswer.blue {
  background-image: url('/gamesAssets/gridattack/blue-land.svg');
  opacity: 0.8;
  background-size: cover;
  box-shadow: inset 0 -10px 0 0 #272d65, inset -10px 0 0 0 #272d65;
}
