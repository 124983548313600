.land {
  position: relative;
}

.character {
  position: absolute;
  width: 100%;
  max-width: 150px;
  height: auto;
}
