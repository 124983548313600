.ddList {
  max-height: 300px;
  overflow-y: auto;
  width: 155px;
  top: 40px;
  z-index: 6;
  left: 25px;
}

.ddListItem:hover {
  color: #fff;
}

.ddListItemLocked:hover {
  color: #dedede;
}

.ddArrow {
  font-size: 12px;
}
