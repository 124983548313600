.code {
  display: flex;
  align-items: flex-start;
  width: 100%;
  max-width: 600px;
  min-width: 300px;
  padding: 12px 0 18px;
  background: #282c34;
  box-shadow: -1px 1px 15px 0px rgb(0 0 0 / 11%);
}

.codeLine {
  margin-top: 11px;
  padding: 0 20px;
  text-align: right;
}

.codeLineNumber {
  color: #707a84;
  line-height: 1.5;
  min-width: 18px;
}

.codeTextarea {
  margin-left: 37px;
  resize: none;
  background: #46505e;
  color: #fff;
  width: 80%;
  padding-left: 2px;
  font-family: Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
}

.codeTextarea:focus {
  outline: none;
}

.nextLevelBtn:disabled {
  opacity: 0.75;
  cursor: default;
}
