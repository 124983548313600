.code {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
  min-width: 300px;
  padding: 12px 0 18px;
  background: #282c34;
  border-radius: 0 6px 6px 6px;
  box-shadow: -1px 1px 15px 0px rgb(0 0 0 / 11%);
}

.tab {
  padding: 6px 16px;
  background: #282c34;
  border-radius: 6px 6px 0 0;
  opacity: 0.5;
  font-size: 14px;
  font-family: ui-monospace, SFMono-Regular, Consolas, 'Liberation Mono', Menlo, monospace;
}

.tabActive {
  opacity: 1;
}

.codeLine {
  margin-top: 11px;
  padding: 0 20px;
  text-align: right;
}

.codeLineNumber {
  color: #707a84;
  line-height: 1.5;
  min-width: 18px;
}

.codeTextarea {
  position: absolute;
  top: 0;
  left: 0;
  resize: none;
  background: transparent;
  color: transparent;
  /* color: #fff; */
  width: 90%;
  padding-left: 2px;
  letter-spacing: normal;
  word-spacing: normal;
  font-family: ui-monospace, SFMono-Regular, Consolas, 'Liberation Mono', Menlo, monospace;
  caret-color: #abb2bf !important;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: pre;

  z-index: 2;
}

.codeHighlighter {
  top: 0;
  left: 0;
  resize: none;
  background: transparent;
  color: #fff;
  width: 90%;
  padding-left: 2px;
  letter-spacing: normal;
  word-spacing: normal;
  font-family: ui-monospace, SFMono-Regular, Consolas, 'Liberation Mono', Menlo, monospace;
  overflow-x: auto;
  z-index: 1;
}

.codeTextarea:focus {
  outline: none;
}

.codeWrapper {
  max-height: 192px;
  overflow-y: auto;
}

@media (max-width: 1024px) {
  .codeLine {
    padding: 0 20px 0 10px;
  }

  .codeTextarea,
  .codeHighlighter {
    width: 95%;
  }
}
