.property {
  display: inline-block;
  background: rgb(181, 123, 79, 0.7);
  color: #fff;
  padding: 4px;
  line-height: 1;
  border-radius: 4px;
  white-space: nowrap;
  -webkit-appearance: none;
}

.propertyDescription {
  max-width: 300px;
}

.propertyPopoverContent {
  color: #fff;
  background: rgba(181, 123, 79, 1);
}

.propertyPopoverArrow {
  position: absolute;
  width: 10px;
  height: 10px;
}

.propertyPopoverArrow:after {
  content: ' ';
  position: absolute;
  top: -5px;
  left: 0;
  transform: rotate(45deg);
  width: 10px;
  height: 10px;
  background-color: rgba(181, 123, 79, 1);
  box-shadow: -1px -1px 1px rgba(0, 0, 0, 0.1);
}

.propertyPopoverContent[data-popper-placement^='top'] > .propertyPopoverArrow {
  bottom: -10px;
}

.propertyPopoverContent[data-popper-placement^='top'] > .propertyPopoverArrow:after {
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
}
