.heroHighlight {
  background: #00800033;
}

.objectInLeftCorner {
  position: relative;
  width: 150%;
  left: 15%;
  bottom: 15%;
  max-width: initial;
}

.hero {
  width: 20%;
  height: 20%;
}
