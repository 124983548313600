/* .userLineSide {
  border: 1px dashed rgba(251, 255, 25, 0.5);
}

.userLineInner {
  border: 1px dashed rgba(251, 255, 25, 0.9);
}

.fieldLineSide {
  border: 1px dashed rgba(241, 226, 15, 0.7);
}

.fieldLineInner {
  border: 1px dashed rgba(241, 226, 15, 0.5);
} */

.lineNameRow {
  position: absolute;
  top: -10px;
  left: 30px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #282d33;
  color: #abb2bf;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  font-size: 14px;
  z-index: 2;
}

.lineNameColumn {
  position: absolute;
  top: 30px;
  left: -10px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #282d33;
  color: #abb2bf;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  font-size: 14px;
  z-index: 2;
}

.columnSignal {
  position: absolute;
  background: #282d33;
  color: #abb2bf;
  padding: 2px 8px;
  width: 48px;
  display: flex;
  justify-content: center;
  top: -26px;
  font-size: 14px;
  overflow: hidden;
  white-space: nowrap;
}

.rowSignal {
  position: absolute;
  background: #282d33;
  color: #abb2bf;
  padding: 2px 8px;
  width: 48px;
  display: flex;
  justify-content: center;
  transform: rotate(-90deg);
  left: -38px;
  font-size: 14px;
  overflow: hidden;
  white-space: nowrap;
}

.columnGapSignal {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  background: #282d33;
  color: #abb2bf;
  padding: 2px;
  top: -5px;
  font-size: 10px;
  z-index: 1;
}

.rowGapSignal {
  position: absolute;
  /* left: 50%;
  transform: translateX(-50%); */
  background: #282d33;
  color: #abb2bf;
  padding: 2px;
  top: 50%;
  font-size: 10px;
  z-index: 1;
  transform: translateY(-50%) rotate(-90deg);
  left: -11px;
}
