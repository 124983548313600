.land {
  position: relative;
}

.land > div {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 10px dashed #a74343;
}
