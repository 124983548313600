.targetHighlight {
  background: #80000033;
}

.badGuy img {
  width: 125%;
  height: 125%;
  max-width: none;
}

.badGuySize-2 img {
  width: 150%;
  height: 150%;
  max-width: none;
}

.badGuySize-3 img {
  width: 175%;
  height: 175%;
  max-width: none;
}

.badGuySize-4 img {
  width: 200%;
  height: 200%;
  max-width: none;
}

.boss img {
  width: 400%;
  height: 300%;
  max-width: none;
  position: relative;
  left: 40%;
  bottom: 40%;
}
