.gameLayout {
  width: 100%;
  min-height: 100vh;
  background: #332a37;
  /* background: linear-gradient(135deg, #2a222b 0%, #3e3142 100%); */
}

.gameLayoutInner {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
}

.description {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 650px;
  min-height: 100vh;
  @apply p-8;
  @apply pb-16;
  @apply text-white;

  padding-bottom: 400px;
}

.codeField {
  position: fixed;
  width: calc(100% - 650px);
  left: 650px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.editorWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 620px;
  padding: 30px 0 30px 30px;
  background: #332a37;
  border-top: 1px solid rgba(189, 180, 182, 0.2);
  z-index: 999;
}

.fieldContainer {
  width: 45vw;
  height: 45vw;
  min-width: 300px;
  min-height: 300px;
  max-width: 650px;
  max-height: 650px;
  border-radius: 15px;
}

.hero {
  width: 20%;
  height: 20%;
}

.heroHighlight {
  background: #00800033;
}

.targetHighlight {
  background: #80000033;
}

.regularText {
  @apply mb-6;
  line-height: 1.7;
  font-size: 18px;
  letter-spacing: 0.5px;
}

.mainTextColor {
  color: #fff;
}

.exampleContainer {
  color: #bdb4b6;
  border-color: #bdb4b6;
}

.exampleChild {
  background: rgba(181, 123, 79, 0.7);
  color: rgb(255, 255, 255);
}

.fieldBackground {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: contain;
  border-radius: 15px;
}

.back1 {
  box-shadow: -5px 5px 25px 3px rgb(71 111 47);
}

.back1 .fieldBackground {
  background-image: url('/gamesAssets/flexbox/back.png');
}

.back2 {
  box-shadow: -5px 5px 25px 3px rgb(166 125 81);
}

.back2 .fieldBackground {
  background-image: url('/gamesAssets/flexbox/back2.png');
}

.back3 {
  box-shadow: -5px 5px 25px 3px rgb(39 45 46);
}

.back3 .fieldBackground {
  background-image: url('/gamesAssets/flexbox/back3.png');
}

.back4 {
  box-shadow: -5px 5px 25px 3px rgb(48 49 52);
}

.back4 .fieldBackground {
  background-image: url('/gamesAssets/flexbox/back4.png');
}

.objectInLeftCorner {
  position: relative;
  width: 150%;
  left: 15%;
  bottom: 15%;
  max-width: initial;
}

.animated {
  animation-duration: 1.5s;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
}

.mainTextColor {
  color: #bdb4b6;
}

.property {
  background: rgb(181, 123, 79, 0.7);
  color: #fff;
  padding: 4px;
  line-height: 1;
  border-radius: 4px;
  white-space: nowrap;
  -webkit-appearance: none;
}

@media (max-width: 1279px) {
  .description {
    max-width: 550px;
  }

  .codeField {
    width: calc(100% - 550px);
    left: 550px;
  }

  .editorWrapper {
    width: 520px;
  }
}

@media (max-width: 1024px) {
  .gameLayoutInner {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .description {
    width: 100%;
    min-height: initial;
    @apply p-4;
  }

  .codeField {
    width: 100%;
    position: initial;
    left: 0;
    flex-direction: column-reverse;
    min-height: initial;
    @apply mb-8;
  }

  .editorWrapper {
    position: initial;
    width: 100%;
    padding: 30px 12px;
    background: transparent;
    border-top: none;
  }

  .fieldContainer {
    width: 95vw;
    height: 95vw;
  }
}

.w50 {
  width: 50px !important;
}

.h50 {
  height: 50px !important;
}
