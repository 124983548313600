.point {
  flex: 2;
  position: relative;
  padding: 0 0 14px 0;
  line-height: 1.5;
  font-weight: 600;
  white-space: nowrap;
  overflow: visible;
  min-width: 0;
  text-align: center;
  border-bottom: 2px solid;
  @apply border-gray-600;
}

.here {
  position: absolute;
  border: 8px solid transparent;
  border-top-color: #dc2625;
  bottom: -4px;
  left: calc(50% - 8px);
}

.isBoss::before {
  content: '';
  display: block;
  width: 8px;
  height: 8px;
  @apply bg-gray-600;
  border-radius: 50%;
  border: 2px solid;
  @apply border-gray-600;
  position: absolute;
  left: calc(50% - 4px);
  bottom: -5px;
  z-index: 3;
  transition: all 0.2s ease-in-out;
}

.isActive:not(:first-child):after,
.isComplete:not(:first-child):after {
  content: '';
  display: block;
  width: 100%;
  position: absolute;
  bottom: -2px;
  left: -50%;
  z-index: 2;
  border-bottom: 2px solid;
  @apply border-red-600;
  @apply bg-red-600;
}

.isActive:before {
  @apply bg-red-600;
  @apply border-red-600;
}

.isComplete:before {
  @apply bg-red-600;
  @apply border-red-600;
}

.point:first-child:before {
  left: 0;
}

.point:first-child,
.point:last-child {
  flex: 1;
}

.isActive.point:last-child::after {
  left: -100%;
  width: 200%;
}

.level {
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate3d(-50%, 230%, 0);
}

.bossImgDead {
  filter: grayscale(0.95);
}
